import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Toaster from '@meforma/vue-toaster'

import "bootstrap/dist/js/bootstrap.js"
import 'bootstrap/dist/css/bootstrap.css'
import './assets/scss/main.scss';

createApp(App)
.use(router)
.use(store)
.use(VueAxios, axios)
.use(Toaster)
.mixin({
    methods: {
      formatDate: function (rawDate, timeOption) {
        if(rawDate){
          const dateNo = new Date(rawDate)
          if(timeOption == "true"){
            var minutes = 0;
            if(dateNo.getMinutes() < 10){
              minutes = "0"+dateNo.getMinutes()
            } else {
              minutes = dateNo.getMinutes()
            }
            var hours = 0;
            if(dateNo.getHours() < 10){
              hours = "0"+dateNo.getHours()
            } else {
              hours = dateNo.getHours()
            }
            return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear()+" - "+hours+":"+minutes
          } else {
            return dateNo.getDate()+"/"+(dateNo.getMonth()+1)+"/"+dateNo.getFullYear();
          }
        } else {
          return ""
        }
      },
      formatMoney: function (value) {
        if(value > 0) {
          return new Intl.NumberFormat('en-US', { style: 'currency',currency: 'KES',currencyDisplay: 'narrowSymbol' }).format(value);
        } else {
          return "0.00";
        }
      },
      formatQty: function (value) {
        if(value > 0) {
          return new Intl.NumberFormat().format(value);
        } else {
          return "0";
        }
      }
    },
  })
.mount('#app')