<template>
  <div class="login">

    <Header :loginHeader="true" />

    <section id="login">
      <div class="container text-center">
        <div class="login-wrapper">
          <div class="img-wrapper">
            <img src="../../assets/images/ticketflow_logo.png" alt="">
          </div>
          <h4>Login</h4>
          <div class="form">
            <div class="form-group">
              <input type="email" placeholder="Email" v-model="email" class="form-control" style="border: 1px solid #abd0ee !important;">
            </div>
            <div class="form-group" v-if="isForgotPassword==false">
            <div class="password-input">
              <input :type="showPassword? 'text': 'password'" placeholder="Password" v-model="password" class="form-control" style="border: 1px solid #abd0ee !important;">
              <button class="toggle-password" @click="togglePasswordVisibility">
                <img v-if="showPassword" src="../../assets/images/icons/visibility_off.svg" alt="eye" />
                <img v-else src="../../assets/images/icons/visibility.svg" alt="eye" />
              </button>
            </div>
            </div>
            <div class="form-group" v-if="isLoading">
              <!-- Show spinners on the buttons -->
              <SpinnerSmall style="margin-left: 10dvw"/>
            </div>
            <div class="form-group" v-else>
              <input v-if="isForgotPassword==false" type="button" value="Login" @click="submitLogin" class="btn-style full">
              <input v-else type="button" value="Reset Password" @click="submitForgotPassword" class="btn-style full">
            </div>
          </div>
          <a href="#" @click="forgotPassword" v-if="isForgotPassword==false">Forgot Password?</a>
          <a href="#" @click="forgotPassword" v-else>Back to Login</a>
        </div>
      </div>
    </section>


  </div>
</template>

<script>

import Header from '../../components/Header';
import Swal from 'sweetalert2';
import SpinnerSmall from '../../components/SpinnerSmall.vue';

export default {
  name: 'LoginPage',
  components: {
    Header,
    SpinnerSmall
  },
  data(){
    return{
      isForgotPassword: false,
      email:"",
      password:"",
      deviceId: '',
      // base_url: 'https://becoming-bat-certain.ngrok-free.app',
      base_url: process.env.VUE_APP_URL,
      isLoading: false,
      showPassword: false,
      deviceDescription: {},
      rules: [
          value => !!value || 'Required.',
          value => (value && value.length >= 4) || 'Min 4 characters',
      ]
    }
  },
  methods:{
    forgotPassword(){
      this.isForgotPassword = !this.isForgotPassword;
    },
    async submitForgotPassword(){
      this.isLoading = true;
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
              email: this.email,
              deviceId: this.deviceId,
              device_description: this.deviceDescription,
          })
      };
      const res = await fetch(`${this.base_url}/auth/reset`, requestOptions)
      const data = await res.json()
      if(data.status == true){
        this.isLoading = false;
        this.isForgotPassword = false
        Swal.fire({
          title: "Password Reset",
          text: "Check your email for your new password",
          icon: "success"
        });
      }else{
        this.isLoading = false;
        Swal.fire({
          title: "Password Reset",
          text: data.message,
          icon: "error"
        });
      }
    },
    async submitLogin(){
      this.isLoading = true;
      const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
              email: this.email,
              password: this.password,
              deviceId: this.deviceId,
              deviceDescription: JSON.stringify(this.deviceDescription),
          })
      };
      const res = await fetch(`${this.base_url}/auth/login`, requestOptions)
      const data = await res.json()
      console.log(data)
      if(data.status == true){
        this.isLoading = false;
        localStorage.setItem('access_token', JSON.stringify(data.access_token))
        this.$router.push("/projects")
      }else{
        this.isLoading = false;
        Swal.fire({
          title: "Login",
          text: data.message,
          icon: "error"
        });
      }
    },
    getDeviceInformation() {
      let deviceInfo = {};
      if ('navigator' in window && 'deviceMemory' in navigator && 'hardwareConcurrency' in navigator) {
        deviceInfo.deviceDescription = navigator.userAgent;
        deviceInfo.deviceMemory = navigator.deviceMemory;
        deviceInfo.hardwareConcurrency = navigator.hardwareConcurrency;
        deviceInfo.language = navigator.language;
        deviceInfo.cookiesEnabled = navigator.cookieEnabled;
        deviceInfo.onlineStatus = navigator.onLine;

        this.deviceId = this.generateDeviceId();
      } else {
        console.error('Device information not supported in this browser.');
      }
      this.deviceDescription=deviceInfo;
    },
    generateDeviceId() {
      // You can customize this method based on the properties you want to include in the ID
      const id = `${navigator.userAgent}-${navigator.platform}-${navigator.language}`;
      // Use a hashing library or other techniques for a more secure and unique ID
      return id;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    }
  },
  mounted() {
    this.getDeviceInformation();
  },
}
</script>

<style scoped>
.password-input {
  position: relative;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}
</style>